body {
  margin: 0;
  background-image: url(https://i.imgur.com/GoxS27D.jpg);
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  background-color: #040402;
  font-family: 'Lato', sans-serif;

}

@media (max-width: 420px) {
  body {
    background-repeat: no-repeat;
    background-size: contain;
    
  }
}

@media (max-width: 770px) {
  body {
    background-repeat: no-repeat;
    background-size: contain;
  }
}