.home {
    display: flex;
    justify-content: center;
    height: 80vh;
    align-items: center;
    color: white;
    flex-direction: column;

}

.hi {
    font-size: 45pt;
    margin: 0;
}


.title {
    margin: 0;
    font-size: 45pt;
    color: #FFE403
}



.buttonDiv{
    margin-top: 20pt;
}


.resumeButton {
    text-decoration: none;
    color: white;
    border: white solid 2px;
    padding: 5pt;
    border-radius: 5px;
    font-size: 13pt;
    margin-right: 15pt;
    transition: ease-out;
    transition-duration: 400ms;
}

.button:hover {
    background-color: #FFE403;
    transition: ease-in;
    color: black;
    transition-duration: 400ms;
}

.aboutBtn {
    text-decoration: none;
    color: white;
    border: white solid 2px;
    padding: 5pt;
    border-radius: 5px;
    font-size: 13pt;
    margin-right: 20pt; 
    transition: ease-out;
    transition-duration: 400ms;
}

.aboutBtn:hover{
    background-color: #FFE403;
    color: black;
    transition: ease-in;
    transition-duration: 400ms;
}

@media (max-width: 420px) {
    .title {
        font-size: 30pt;
    }
    .subTitle {
        font-size: 11pt;
    }
}

@media (max-width: 320px) {
    .title {
        font-size: 25pt;
    }
    .subTitle{
        font-size: 9pt;
    }
}