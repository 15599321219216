.mainResumeDiv{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40pt;

}


.view {
    margin-bottom: 40pt;
    color: black;
}

.resume {
    height: 900pt;
    width: 51vw;
    border: darkgrey 5px solid;
    border-radius: 5px;
    margin-bottom: 60pt;
}

@media (max-width: 420px) {
    .resume {
        height: 100vh;
        width: 85vw;
    }
}

@media (min-width: 750px) and (max-width: 1030px) {
    .resume {
        width: 90vw
    }
}