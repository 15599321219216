.home {
    display: flex;
    justify-content: center;
    height: 80vh;
    align-items: center;
    color: white;
    flex-direction: column;

}

.hi {
    font-size: 45pt;
    margin: 0;
}


.title {
    margin: 0;
    font-size: 45pt;
    color: #FFE403
}



.buttonDiv{
    margin-top: 20pt;
}


.resumeButton {
    text-decoration: none;
    color: white;
    border: white solid 2px;
    padding: 5pt;
    border-radius: 5px;
    font-size: 13pt;
    margin-right: 15pt;
    transition: ease-out;
    transition-duration: 400ms;
}

.button:hover {
    background-color: #FFE403;
    transition: ease-in;
    color: black;
    transition-duration: 400ms;
}

.aboutBtn {
    text-decoration: none;
    color: white;
    border: white solid 2px;
    padding: 5pt;
    border-radius: 5px;
    font-size: 13pt;
    margin-right: 20pt; 
    transition: ease-out;
    transition-duration: 400ms;
}

.aboutBtn:hover{
    background-color: #FFE403;
    color: black;
    transition: ease-in;
    transition-duration: 400ms;
}

@media (max-width: 420px) {
    .title {
        font-size: 30pt;
    }
    .subTitle {
        font-size: 11pt;
    }
}

@media (max-width: 320px) {
    .title {
        font-size: 25pt;
    }
    .subTitle{
        font-size: 9pt;
    }
}
.nav {
    display: flex;
    justify-content: space-around;
    padding: 5px;
    background-color: rgba(128, 128, 128, 0.425);
}

.logo {
  height: 45pt;

}


.navItems {
    text-decoration: none;
    color: white;
    font-size: 15pt;
    margin-top: 12pt;
    transition: ease-out;
    transition-duration: 400ms;
}

.navItems:hover {
    color: #FFE403;
    transition: ease-in;
    transition-duration: 200ms;
}

@media (max-width: 320px) {
    .logo {
        font-size: 12pt
    }
    .navItems{
        font-size: 12pt
    }
}
.mainProjectsDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 23vh;
    
}

.projects {
    height: 150px;
    border: white 2px solid;
    border-radius: 10px;
}

.philosophyPic {
    height: 150px;
    width: 283.5px;
    border: whitesmoke 2px solid;
    border-radius: 10px;
}


.projectTitle{
    text-align: center;
    color: white;
    transition: ease-out;
    transition-duration: 400ms;
}

.projectTitle:hover {
    color: yellow;
    transition: ease-in;
    transition-duration: 200ms;
}



.description {
    text-align: center;
    color: white;
}


a {
    text-decoration: none;
}

.madeWith {
    text-align: center;
    color: white;
}


.firstBtn{
    color: black;
    border-radius: 5px;
    background-color: #FFE403;
    width: 140px;
    padding: 2px;
    margin: auto;
    text-align: center;
    transition: ease-out;
    transition-duration: 400ms;
}


.firstBtn:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
    transition: ease-in;
    transition-duration: 200ms;
    
}


.caulBtn{
    color: black;
    border-radius: 5px;
    background-color: #FFE403;
    width: 140px;
    padding: 2px;
    margin: auto;
    text-align: center;
    transition: ease-out;
    transition-duration: 400ms;
}


.caulBtn:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
    transition: ease-in;
    transition-duration: 200ms;
    
}



.liveBtn {
    color: black;
    border-radius: 5px;
    background-color: #FFE403;
    width: 100px;
    padding: 2px;
    margin: auto;
    text-align: center;
    transition: ease-out;
    transition-duration: 400ms;
}

.liveBtn:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
    transition: ease-in;
    transition-duration: 200ms;
    
}

.lastLiveBtn {
    color: black;
    border-radius: 5px;
    background-color: #FFE403;
    width: 140px;
    margin: auto;
    padding: 2px;
    text-align: center;
    /* margin-bottom: 55pt; */
    transition: ease-out;
    transition-duration: 400ms;
}


.lastLiveBtn:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
    transition: ease-in;
    transition-duration: 200ms;
}



@media (max-width: 420px) {
    .mainProjectsDiv{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 60pt
    }
    .projects{
        height: 125px;
        
    }
    .firstBtn {
        background-color: #FFE403;
    }
    .caulBtn{
        background-color: #FFE403;
    }
    .liveBtn {
        margin-bottom: 30px;
        background-color: #FFE403;

    }
    .lastLiveBtn{
        background-color: #FFE403;
    
    }
}

@media (max-width: 770px) {

    
    .projects {
        height: 110px;
    }
    .philosophyPic{
        height: 110px;
        width: 200px;
        margin-left: 5vw
    }
    .description {
        font-size: 10pt;
    }
    .madeWith {
        font-size: 10pt;
    }
    .caulBtn{
        margin-bottom: 65px;
    }
}
.mainResumeDiv{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40pt;

}


.view {
    margin-bottom: 40pt;
    color: black;
}

.resume {
    height: 900pt;
    width: 51vw;
    border: darkgrey 5px solid;
    border-radius: 5px;
    margin-bottom: 60pt;
}

@media (max-width: 420px) {
    .resume {
        height: 100vh;
        width: 85vw;
    }
}

@media (min-width: 750px) and (max-width: 1030px) {
    .resume {
        width: 90vw
    }
}

.mainAboutDiv{
    display: flex;
    justify-content: center;
    margin-top: 10vh;
}

.profilePic {
    height: 375px;
    width: 300px;
    border-radius: 70px;
    border: white 4px solid;
}

.adjectives {
    color:  #FFE403;
}



.aboutMe {
    margin: auto;
    width: 50vw;
    margin-top: 30pt;
    font-size: 15pt;
    margin-bottom: 80pt;
    color: white;
    background-color: rgba(128, 128, 128, 0.425);
    border-radius: 10px;
    padding: 30px;
    text-align: justify;
}


@media (max-width: 420px) {
    .aboutMe{
        width: 80vw;
        margin-top: 20pt;
        text-align: justify;
        margin-bottom: 50pt;
    }
}
.container {
    background-color:  rgba(128, 128, 128, 0.425);
    width: 35vw;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 24vh;
    border-radius: 10px;
    color: white;
    padding: 15px;
    text-align: center;
}

.message {
    margin-top: 15px;
    margin-bottom: 10px;
}

.mailBtn{
    color: black;
    border-radius: 5px;
    background-color: #FFE403;
    width: 90px;
    padding: 2px;
    margin: auto;
    text-align: center;
    transition: ease-out;
    transition-duration: 400ms;
}


.mailBtn:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
    transition: ease-in;
    transition-duration: 200ms;
    
}

@media (max-width: 420px) {
    .container {
        width: 90vw;
        text-align: center;
        height: 200px;
    }
}




.footer {
    display: flex;
    justify-content: center;
    width: 100vw;
    position: fixed;
    bottom: 0;

}

.social:hover {
    transition: ease-in;
    transition-duration: 200ms;
    background-color: #FFE403;
    border-radius: 7px
}

.social {
    transition: ease-out;
    transition-duration: 400ms;
}
body {
  margin: 0;
  background-image: url(https://i.imgur.com/GoxS27D.jpg);
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  background-color: #040402;
  font-family: 'Lato', sans-serif;

}

@media (max-width: 420px) {
  body {
    background-repeat: no-repeat;
    background-size: contain;
    
  }
}

@media (max-width: 770px) {
  body {
    background-repeat: no-repeat;
    background-size: contain;
  }
}
