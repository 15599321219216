.mainProjectsDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 23vh;
    
}

.projects {
    height: 150px;
    border: white 2px solid;
    border-radius: 10px;
}

.philosophyPic {
    height: 150px;
    width: 283.5px;
    border: whitesmoke 2px solid;
    border-radius: 10px;
}


.projectTitle{
    text-align: center;
    color: white;
    transition: ease-out;
    transition-duration: 400ms;
}

.projectTitle:hover {
    color: yellow;
    transition: ease-in;
    transition-duration: 200ms;
}



.description {
    text-align: center;
    color: white;
}


a {
    text-decoration: none;
}

.madeWith {
    text-align: center;
    color: white;
}


.firstBtn{
    color: black;
    border-radius: 5px;
    background-color: #FFE403;
    width: 140px;
    padding: 2px;
    margin: auto;
    text-align: center;
    transition: ease-out;
    transition-duration: 400ms;
}


.firstBtn:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
    transition: ease-in;
    transition-duration: 200ms;
    
}


.caulBtn{
    color: black;
    border-radius: 5px;
    background-color: #FFE403;
    width: 140px;
    padding: 2px;
    margin: auto;
    text-align: center;
    transition: ease-out;
    transition-duration: 400ms;
}


.caulBtn:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
    transition: ease-in;
    transition-duration: 200ms;
    
}



.liveBtn {
    color: black;
    border-radius: 5px;
    background-color: #FFE403;
    width: 100px;
    padding: 2px;
    margin: auto;
    text-align: center;
    transition: ease-out;
    transition-duration: 400ms;
}

.liveBtn:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
    transition: ease-in;
    transition-duration: 200ms;
    
}

.lastLiveBtn {
    color: black;
    border-radius: 5px;
    background-color: #FFE403;
    width: 140px;
    margin: auto;
    padding: 2px;
    text-align: center;
    /* margin-bottom: 55pt; */
    transition: ease-out;
    transition-duration: 400ms;
}


.lastLiveBtn:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
    transition: ease-in;
    transition-duration: 200ms;
}



@media (max-width: 420px) {
    .mainProjectsDiv{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 60pt
    }
    .projects{
        height: 125px;
        
    }
    .firstBtn {
        background-color: #FFE403;
    }
    .caulBtn{
        background-color: #FFE403;
    }
    .liveBtn {
        margin-bottom: 30px;
        background-color: #FFE403;

    }
    .lastLiveBtn{
        background-color: #FFE403;
    
    }
}

@media (max-width: 770px) {

    
    .projects {
        height: 110px;
    }
    .philosophyPic{
        height: 110px;
        width: 200px;
        margin-left: 5vw
    }
    .description {
        font-size: 10pt;
    }
    .madeWith {
        font-size: 10pt;
    }
    .caulBtn{
        margin-bottom: 65px;
    }
}