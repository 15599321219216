.container {
    background-color:  rgba(128, 128, 128, 0.425);
    width: 35vw;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 24vh;
    border-radius: 10px;
    color: white;
    padding: 15px;
    text-align: center;
}

.message {
    margin-top: 15px;
    margin-bottom: 10px;
}

.mailBtn{
    color: black;
    border-radius: 5px;
    background-color: #FFE403;
    width: 90px;
    padding: 2px;
    margin: auto;
    text-align: center;
    transition: ease-out;
    transition-duration: 400ms;
}


.mailBtn:hover {
    color: white;
    background-color: black;
    border: 1px solid white;
    transition: ease-in;
    transition-duration: 200ms;
    
}

@media (max-width: 420px) {
    .container {
        width: 90vw;
        text-align: center;
        height: 200px;
    }
}



