
.mainAboutDiv{
    display: flex;
    justify-content: center;
    margin-top: 10vh;
}

.profilePic {
    height: 375px;
    width: 300px;
    border-radius: 70px;
    border: white 4px solid;
}

.adjectives {
    color:  #FFE403;
}



.aboutMe {
    margin: auto;
    width: 50vw;
    margin-top: 30pt;
    font-size: 15pt;
    margin-bottom: 80pt;
    color: white;
    background-color: rgba(128, 128, 128, 0.425);
    border-radius: 10px;
    padding: 30px;
    text-align: justify;
}


@media (max-width: 420px) {
    .aboutMe{
        width: 80vw;
        margin-top: 20pt;
        text-align: justify;
        margin-bottom: 50pt;
    }
}