.footer {
    display: flex;
    justify-content: center;
    width: 100vw;
    position: fixed;
    bottom: 0;

}

.social:hover {
    transition: ease-in;
    transition-duration: 200ms;
    background-color: #FFE403;
    border-radius: 7px
}

.social {
    transition: ease-out;
    transition-duration: 400ms;
}